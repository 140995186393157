class OpalWoocommerceMain {
    static instance;
    static getInstance() {
        if (!OpalWoocommerceMain.instance) {
            OpalWoocommerceMain.instance = new OpalWoocommerceMain();
        }
        return OpalWoocommerceMain.instance;
    }

    constructor() {
        this.initCarousel();
        this.addedToCart();
        this.initWishlist();
        //this.initSlideProduct();
        this.initSelectOrder();
    }

    initSelectOrder(){
        if($('body').is('.post-type-archive-product, .tax-product_cat')) {

            let x, i, j, selElmnt, a, b, c;
            /*look for any elements with the class "custom-select":*/
            x = document.getElementsByClassName("woocommerce-ordering");
            let $select = $('.woocommerce-ordering .orderby');
            for (i = 0; i < x.length; i++) {
                selElmnt = x[i].getElementsByTagName("select")[0];
                /*for each element, create a new DIV that will act as the selected item:*/
                a = document.createElement("DIV");
                a.setAttribute("class", "select-selected");
                a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
                x[i].appendChild(a);
                /*for each element, create a new DIV that will contain the option list:*/
                b = document.createElement("DIV");
                b.setAttribute("class", "select-items select-hide");
                for (j = 1; j < selElmnt.length; j++) {
                    /*for each option in the original select element,
                    create a new DIV that will act as an option item:*/
                    c = document.createElement("DIV");
                    c.innerHTML = selElmnt.options[j].innerHTML;
                    c.addEventListener("click", function (e) {
                        /*when an item is clicked, update the original select box,
                        and the selected item:*/
                        let y, i, k, s, h;
                        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
                        h = this.parentNode.previousSibling;
                        for (i = 0; i < s.length; i++) {
                            if (s.options[i].innerHTML == this.innerHTML) {
                                s.selectedIndex = i;
                                h.innerHTML = this.innerHTML;
                                y = this.parentNode.getElementsByClassName("same-as-selected");
                                for (k = 0; k < y.length; k++) {
                                    y[k].removeAttribute("class");
                                }
                                this.setAttribute("class", "same-as-selected");
                                break;
                            }
                        }
                        h.click();
                        $select.trigger('change');
                    });
                    b.appendChild(c);
                }
                x[i].appendChild(b);
                a.addEventListener("click", function (e) {
                    /*when the select box is clicked, close any other select boxes,
                    and open/close the current select box:*/
                    e.stopPropagation();
                    closeAllSelect(this);
                    this.nextSibling.classList.toggle("select-hide");
                    this.classList.toggle("select-arrow-active");
                });
            }

            function closeAllSelect(elmnt) {
                /*a function that will close all select boxes in the document,
                except the current select box:*/
                let x, y, i, arrNo = [];
                x = document.getElementsByClassName("select-items");
                y = document.getElementsByClassName("select-selected");
                for (i = 0; i < y.length; i++) {
                    if (elmnt == y[i]) {
                        arrNo.push(i)
                    } else {
                        y[i].classList.remove("select-arrow-active");
                    }
                }
                for (i = 0; i < x.length; i++) {
                    if (arrNo.indexOf(i)) {
                        x[i].classList.add("select-hide");
                    }
                }
            }

            /*if the user clicks anywhere outside the select box,
            then close all select boxes:*/
            document.addEventListener("click", closeAllSelect);
        }
    }

    initSlideProduct() {
        $('.product-block .product-img-wrap').each((index, element) => {
            let $slider = $(element);
            let $container = $slider.closest('.product-block');
            $slider.flexslider({
                selector: '.inner > .product-image',
                animation: 'slide',
                controlNav: true,
                directionNav: true,
                slideshow: false,
                smoothHeight: false,
                before: (slider) => {

                    if (slider.direction === 'next') {
                        this.activeGalleryThumbnail($container, slider.currentSlide + 1);
                    } else {
                        this.activeGalleryThumbnail($container, slider.currentSlide - 1);
                    }
                },
                after: (slider) => {
                    this.activeGalleryThumbnail($container, slider.currentSlide);
                },
                start: function(){
                    setTimeout(function(){
                        $slider.addClass('flexslider-loaded');
                        $slider.data('flexslider').resize();
                    }, 500);
                }
                // 'animationSpeed': 500,
            });
            $('.gallery-nav-next', $container).on('click', function () {
                $container.find('.flex-nav-next a').trigger('click');
            });

            $('.gallery-nav-prev', $container).on('click', function () {
                $container.find('.flex-nav-prev a').trigger('click');
            });

            $('.gallery_item', $container).on('click', (event) => {
                let number = $(event.currentTarget).data('number');
                $slider.data('flexslider').flexAnimate(number);
                this.activeGalleryThumbnail($container, number);
            })
        })
    }

    activeGalleryThumbnail($container, key) {
        let $gallery = $('.woocommerce-loop-product__gallery', $container);
        $('.gallery_item', $gallery).removeClass('active');
        $('.gallery_item[data-number="' + key + '"]', $gallery).addClass('active');
    }

    initVariableItem() {
        $('.otf-wrap-swatches .swatch').on('click', (event) => {
            event.preventDefault();
            let $item = $(event.currentTarget);
            let $container = $item.closest('.product');

            if ($item.hasClass('checked')) {
                $container.removeClass('product-swatched');
                $item.removeClass('checked');
                $container.find('.product-image-swatch').remove();
            } else {
                $item.closest('.otf-wrap-swatches').find('.swatch').removeClass('checked');
                $container.addClass('product-swatched product-swatch-loading');
                $item.addClass('checked');
                let object = $item.data('image');
                if (object.src) {
                    let $img = $('<img />').on('load', (event) => {
                        $container.find('.product-image-swatch').remove();
                        $container.removeClass('product-swatch-loading');
                        $container.find('.product-image').first().before(`<div class="product-image-swatch">${event.currentTarget.outerHTML}</div>`);
                    }).attr({
                        src: object.src,
                    });
                    if (object.srcset) {
                        $img.attr(srcset, object.srcset);
                    }
                }
            }
        })
    }

    initCarousel() {
        $('.woocommerce-product-carousel').each((index, element) => {
            let $element = $(element);
            let items = $element.data('columns');
            let itemMobile = 1;
            let items768 = 2;
            let items980 = items;
            if (items > 3) {
                items980 = items - 1;
                items768 = items - 2;
            }
            if (items < 2) {
                items768 = items;
            }
            $element.find('ul.products').owlCarousel({
                items: items,
                nav: true,
                dots: false,
                responsive: {
                    0: {
                        items: itemMobile,
                    },
                    480: {
                        items: itemMobile,
                    },
                    768: {
                        items: items768
                    },
                    980: {
                        items: items980
                    },
                    1200: {
                        items: items
                    }
                }
            })
        });
    }

    addedToCart() {
        let $product;
        $('body').on('adding_to_cart', function (event, button) {
            $product = button.closest('.product');
        }).on('added_to_cart', function () {
            let src = $product.find('img').first().attr('src');
            let productName = $product.find('.woocommerce-loop-product__title').text();
            let template = wp.template('added-to-cart-template');
            $('#page-title-bar').after(template({src: src, name: productName}));
            setTimeout(function () {
                $('.notification-added-to-cart').addClass('hide');
            }, 3000);
        });
    }

    initWishlist() {
        $(document).on('added_to_wishlist removed_from_wishlist', function () {
            let $wishlist = $('.opal-header-wishlist');
            $.ajax({
                url: yith_wcwl_l10n.ajax_url,
                data: {
                    action: 'osf_update_wishlist_count'
                },
                dataType: 'json',
                success: function (data) {
                    $wishlist.find('.count').text(data.count);
                },
                beforeSend: function () {
                },
                complete: function () {
                }
            })
        })
    }
}

class OpalTheme_Woocommerce_filter {
    selectorsClick = []
    selectorsChange = []

    constructor() {
        this.selectorsClick = [
            '#secondary .widget .product-categories a',
            '#secondary .widget .product-brands a',
            '#secondary .widget .woocommerce-widget-layered-nav-list a',
            '#secondary .widget.widget_layered_nav_filters a',
            '#secondary .widget.widget_rating_filter a',
            '#secondary .widget_product_tag_cloud a',

            '#opal-canvas-filter .widget .product-categories a',
            '#opal-canvas-filter .widget .product-brands a',
            '#opal-canvas-filter .widget .woocommerce-widget-layered-nav-list a',
            '#opal-canvas-filter .widget.widget_layered_nav_filters a',
            '#opal-canvas-filter .widget.widget_rating_filter a',
            '#opal-canvas-filter .widget_product_tag_cloud a',

            '#main ul.products + .woocommerce-pagination a',
            '#secondary .widget .product-brands a',
            '.otf-active-filters .widget_layered_nav_filters a',
            '.otf-active-filters .clear-all',
        ];
        this.initDisplayMode();
        this.init();
        this.initCategoriesDropdown();
        this.initRecentlyReviewed();
        this.priceSlideChange();
        this.initOrdering();

        $(window).on("popstate", () => {
            if (history.state && history.state.woofilter) {
                this.renderHtml(history.state);
            }
        });
    }

    init() {
        $('body').on('click', this.selectorsClick.join(','), (event) => {
            event.preventDefault();
            let $this = $(event.currentTarget);
            let url = $this.attr('href');
            this.sendRequest(url);
        }).on('click', '.display-mode button[type="submit"]', (event) => {
            event.preventDefault();
            let $this = $(event.currentTarget);
            if (!$this.hasClass('active')) {
                let value = $this.val();
                let objUrl = new URL(window.location.href);
                let searchParams = new URLSearchParams(window.location.search);
                searchParams.set('display', value);
                objUrl.search = searchParams.toString();
                this.sendRequest(objUrl.toString(), false);
            }
        });
    }

    scrollUp() {

        let position = $('#primary').offset().top;
        if ($('body').hasClass('opal-header-sticky')) {
            position -= $('#opal-header-sticky').outerHeight();
        }

        if ($('#wpadminbar').length > 0) {
            position -= $('#wpadminbar').outerHeight();
        }
        if ($(window).scrollTop() > position) {
            $('html, body').animate({scrollTop: position}, 'slow');
        }
    }

    replaceUrl(url) {
        let layout = this.getCookie('otf_woocommerce_display', 'grid');
        if (layout === 'list') {
            if (url.indexOf('?') !== -1) {
                url = url + '&display=list';
            } else {
                url = url + '?display=list';
            }
        }

        return url;
    }

    initDisplayMode() {
        if ($('body').hasClass('opal-woocommerce-archive')) {
            let display = this.getCookie('otf_woocommerce_display', 'grid');
            if (display === 'list') {
                let url = window.location.href;
                let objUrl = new URL(url);
                let dp = objUrl.searchParams.get("display");
                if (!dp) {
                    this.sendRequest(url);
                }
            }
        }
    }

    sendRequest(url, replace = true) {
        if (replace) {
            url = this.replaceUrl(url);
        }
        this.initLoading(true);
        $.post(url, (data) => {
            if (data) {
                let $html = $(data);
                let state = {
                    woofilter: true,
                    title    : $html.filter('title').text(),
                    sidebar  : $html.find('#secondary').html(),
                    content  : $html.find('#primary').html(),
                    filter   : $html.find('.opal-canvas-filter-wrap').html(),
                    pagetitle: $html.find('#page-title-bar').html(),
                };
                this.renderHtml(state);
                $(document).trigger('opal-fill-success');
                window.history.pushState(state, state.title, url);
            }
            this.initLoading(false)
        })
    }

    renderHtml(state) {
        this.scrollUp();
        $('head title').text(state.title);
        $('#primary').html(state.content);
        $('#secondary').html(state.sidebar);
        $('#opal-canvas-filter .opal-canvas-filter-wrap').html(state.filter);
        $('#page-title-bar').html(state.pagetitle);
        this.initPriceSlider();
        this.initCategoriesDropdown();
        this.initOrdering();
        this.initLoading(false);
        //OpalWoocommerceMain.getInstance().initSlideProduct();
        OpalWoocommerceMain.getInstance().initSelectOrder();
    }

    initLoading(check) {
        if (check) {
            $('body').addClass('opal-filter-loading').append('<div id="opal-woocommerce-loading"></div>');
        } else {
            $('body').removeClass('opal-filter-loading');
            $('#opal-woocommerce-loading').remove();
        }
    }

    initRecentlyReviewed() {
        let recentlyTitle = $('h2.otf-woocommerce-recently-viewed');
        let recentlyContent = $('.otf-product-recently-content');
        recentlyContent.hide();

        recentlyTitle.on('click', function (e) {
            e.stopPropagation();
            if (!recentlyTitle.hasClass('active')) {
                recentlyContent.show(400);
                recentlyTitle.addClass('active');
                let scrollHeight = $(document).height();
                let contentHeight = $('.widget_recently_viewed_products').height();
                $("html, body").animate({scrollTop: scrollHeight}, contentHeight + 10);
            } else {
                recentlyContent.hide(400);
                recentlyTitle.removeClass('active');
            }
        });

    }


    initCategoriesDropdown() {
        $('.widget_product_categories ul li.cat-item').each(function () {
            let _this = $(this);
            _this.find('ul.children').hide();
            if (_this.find('.current-cat').length > 0) {
                _this.find('ul.children').show();
                $(this).append('<i class="opened fa fa-chevron-up"></i>');
            } else if (_this.find('ul.children').length > 0) {
                $(this).append('<i class="closed fa fa-chevron-down"></i>');
            }

        });
        $("body").on("click", '.widget_product_categories ul li.cat-item .closed', function () {
            $(this).parent().find('ul.children').first().show(400);
            $(this).parent().addClass('open');
            $(this).removeClass('closed').removeClass('fa-chevron-down').addClass('opened').addClass('fa-chevron-up');
        });
        $("body").on("click", '.widget_product_categories ul li.cat-item .opened', function () {
            $(this).parent().find('ul.children').first().hide(400);
            $(this).parent().addClass('close').removeClass('open');
            $(this).removeClass('opened').removeClass('fa-chevron-up').addClass('closed').addClass('fa-chevron-down');
        });
    }

    initOrdering() {
        setTimeout(() => {
            $('.woocommerce-ordering').off('change');
            $('.woocommerce-ordering').on('change', (event) => {
                let $select = $(event.currentTarget).find('.orderby');
                let url = window.location.href.replace(/&orderby(=[^&]*)?|^orderby(=[^&]*)?&?/g, '')
                    .replace(/\?orderby(=[^&]*)?|^orderby(=[^&]*)?&?/g, '?')
                    .replace(/\?$/g, '')


                if (url.indexOf('?') !== -1) {
                    url = url + `&orderby=${$select.val()}`;
                } else {
                    url = url + `?orderby=${$select.val()}`;
                }
                this.sendRequest(url);
            })
        }, 100);
    }

    initPriceSlider() {
        setTimeout(() => {
            if ($('.price_slider:not(.ui-slider)').length <= 0) {
                return true;
            }
            $('input#min_price, input#max_price').hide();
            $('.price_slider, .price_label').show();

            let min_price         = $('.price_slider_amount #min_price').data('min'),
                max_price         = $('.price_slider_amount #max_price').data('max'),
                current_min_price = $('.price_slider_amount #min_price').val(),
                current_max_price = $('.price_slider_amount #max_price').val();

            $('.price_slider:not(.ui-slider)').slider({
                range  : true,
                animate: true,
                min    : min_price,
                max    : max_price,
                values : [current_min_price, current_max_price],
                create : function () {

                    $('.price_slider_amount #min_price').val(current_min_price);
                    $('.price_slider_amount #max_price').val(current_max_price);

                    $(document.body).trigger('price_slider_create', [current_min_price, current_max_price]);
                },
                slide  : function (event, ui) {

                    $('input#min_price').val(ui.values[0]);
                    $('input#max_price').val(ui.values[1]);

                    $(document.body).trigger('price_slider_slide', [ui.values[0], ui.values[1]]);
                },
                change : function (event, ui) {

                    $(document.body).trigger('price_slider_change', [ui.values[0], ui.values[1]]);
                }
            });
        }, 200);
    }

    priceSlideChange() {
        $(document.body).bind('price_slider_change', (event, min, max) => {
            let url = window.location.href.replace(/(min_price=\d+\&*|max_price=\d+\&*)/g, '')
                .replace(/\?$/g, '')
            if (url.indexOf('?') !== -1) {
                url = url + `&min_price=${min}&max_price=${max}`;
            } else {
                url = url + `?min_price=${min}&max_price=${max}`;
            }
            this.sendRequest(url);
        })
    }

    getCookie(cname, _default) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    setCookie(cname, cvalue, exdays = 10) {
        let d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }


}

$(document).ready(function () {
    OpalWoocommerceMain.getInstance();
    new OpalTheme_Woocommerce_filter();
});